<template>
	<BaseLayout>
		<Toolbar class="w-full bg-primary p-5" @clickLeft="$router.push('/signin')">
			<template v-slot:start>
				<p class="text-white">
					<Icon class="h-5 w-5" icon="angleLeft" />
				</p>
			</template>
			{{ $t('forgotPassword.forgotPassword') }}
		</Toolbar>
		<div class="flex w-full justify-center bg-primary pb-16 pt-6 iPhone:pb-5 iPhone:pt-2">
			<img src="assets/logo.svg" class="w-52" alt="" loading="lazy" />
		</div>

		<div class="px-5 pt-10">
			<p class="text-md py-2 text-secondary">{{ $t('forgotPassword.resetPassword') }}</p>
			<form class="my-5" @submit.stop.prevent="forgotPassword">
				<div class="w-full rounded-xl bg-light-grey p-4">
					<input v-model="emailAddress" class="w-full bg-transparent placeholder-muted" type="email" :placeholder="$t('forgotPassword.emailAddress')" required />
				</div>
				<p class="py-5 text-xs text-muted">{{ $t('forgotPassword.registeredEmail') }}</p>
				<Button type="submit" :loading="isLoading" :disabled="isLoading" :class="emailAddress ? 'bg-primary' : 'bg-muted'">{{ $t('forgotPassword.submitEmail') }}</Button>
			</form>
		</div>
	</BaseLayout>
	<Toast v-if="error" @close="error = null"> {{ error.code }}</Toast>
	<Toast v-if="success" @close="success = null" class="bg-green-500">{{ $t('forgotPassword.submitSuccess') }}</Toast>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'

export default {
	setup() {
		const store = useStore()

		const emailAddress = ref('')

		const success = ref(null)
		const error = ref(null)
		const isLoading = computed(() => store.state.loadingState)

		async function forgotPassword() {
			try {
				store.commit('setLoadingState', { loadingState: true })
				success.value = null
				error.value = null
				await store.dispatch('forgotPassword', { emailAddress: emailAddress.value })
				success.value = { code: 'auth/password-reset-email-sent', message: 'Success (auth/password-reset-email-sent).' }
				store.commit('setLoadingState', { loadingState: false })
			} catch (e) {
				console.error(e)
				error.value = e
				store.commit('setLoadingState', { loadingState: false })
				setTimeout(() => {
					error.value = null
				}, 10000)
			}
		}

		return {
			emailAddress,
			success,
			error,
			isLoading,
			forgotPassword,
		}
	},
}
</script>
